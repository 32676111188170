const endPoints = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    CHANGE_FORGOT_PASSWORD: '/change-forgot-password',
    PROFILE: '/profile',
    COMPANY: '/company',
    REASON_CODE: '/reason-code/',
    UPLOAD_FILES: '/upload',
    JOB_TITLE: '/title/',
    CLUBS: '/clubs/',
    EMPLOYEE: '/employee/',
    EMPLOYEE_TYPE: '/employee-data/',
    EMPLOYEE_PAY_TYPE: '/employee-class/',
    DEFAULT_CALENDAR: '/employee-appointment-sort/',
    GET_PERMISSIONS: '/permissions/',
    SECURITY_ROLE: '/security-role/',
    DEPARTMENTS: '/department/',
    EMPLOYEE_DEPARTMENTS: '/employee-department/',
    EMPLOYEE_CLASSES: '/employee-classes/',
    UPDATE_CLASS_LEVEL: '/employee-classes-level/',
    UPDATE_APPOINTMENT_CLASS_LEVEL: '/employee-appointment-level/',
    LEVELS: '/level/',
    LOCATION_TYPE: '/location-type/',
    LOCATIONS: '/location/',
    COMPAIGNS_GROUP: '/campaign-group/',
    COMPAIGN: '/campaign/',
    RESOURCE_TYPE: '/resource-type/',
    RESOURCES: '/resource/',
    AGREEMENT_CATEGORY: '/agreement-category/',
    REFERRAL_GROUPS: '/referral-group/',
    COMMISSION_GROUPS: '/commission-group/',
    VENDORS: '/vendor/',
    CATEGORIES: '/inventory-category/',
    FILTER_SETS: '/filter-set/',
    TAGS: '/tag/',
    PROFIT_CENTERS: '/profit-center/',
    MANAGE_EMPLOYEE_CERTIFICATES: '/certification/',
    SUSTITITION_OPTIONS: '/employee-substitute-option/',
    EMPLOYEE_APPOINTMENT: '/employee-appointment/',
    EMPLOYEE_SALES_CODE: '/sales-code/',
    EMPLOYEE_APPOINTMENT_IS_DEFAULT: '/employee-appointment-default-pay/',
    AGREEMENT_TEMPLATE: '/agreement-template/',
    ASSETS: '/asset/',
    SALES_COMMISSION: '/sales-commission/',
    NOTES: '/notes',
    ACCESS_SCHEDULE: '/access-schedule/',
    INVENTORY_CATALOG: '/inventory-catalog/',
    INVENTORY_CATALOG_FILTER: '/inventory-catalog-filter/',

    INVENTORY_CATALOG_USAGE: '/inventory-usage/',
    INVENTORY_CATALOG_USAGE_TYPE: '/inventory-usage-type/',
    INVENTORY_CATALOG_VARIATION: '/inventory-variation/',
    INVENTORY_CATALOG_VARIATION_DETAIL: '/inventory-variation-detail/',
    INVENTORY_CATALOG_VARIATION_ALL: '/inventory-variation-all/',
    INVENTORY_SUB_VARIATION: '/inventory-sub-variation/',
    SUB_VARIATION_DELETE: '/inventory-sub-variation/',
    SCHEDULE_EVENTS: '/event-setup/',
    SCHEDULE_EVENTS_LEVEL: '/event-setup-level-services/',
    SCHEDULE_EVENT_LEVEL: '/event-setup-level-service/',
    SCHEDULE_EVENT_CLEAR: '/event-setup-level-services-clear/',
    EVENT_CATEGORY: '/event-category/',
    CLASSES: '/schedule-classes/',
    MEMBERSHIP_TYPES: '/member-setup/',
    ASSESSED_FEE: '/assessed-fee/',
    MEMBERSHIP_PLAN: '/membership-plan/',
    AGREEMENT_PROMOTION: '/agreement-promotion/',
    TAXES: '/tax/',
    PAYMENT_METHODS: '/payment-method/',
    DISCOUNT_TYPES: '/discount/',
    MEMBERS: '/add-member/',
    MEMBER_BARCODE: '/add-member-barcode/',
    GET_MEMBERS: '/search-member',
    SELL_PLAN: '/plan/',
    DRAFT_PLAN: '/draft/',
    MEMBER_SELL_PLAN: '/personal-data/',
    UNIQUE_AGREEMENT: '/unique-agrement/',
    PLAN_AGREEMENT: '/digital-aggrement/',
    SCHEDULING_OPTIONS: '/schedule-option/',
    RECENT_SUGGESSIONS: '/suggestion-list/',
    ADD_RECENT_SUGGESSION: '/suggestion/',
    DASHBOARD: '/dashboard-count/',
    PROMO_CODE: '/promo-code/',
    REGISTERS: '/cash-register/',
    VERIFY_ACCESS_CODE: '/verify-cash-register/',
    CASH_REGISTER_CHECK_IN: '/cash-register-check-in/',
    CASH_REGISTER_CHECK_OUT: '/cash-register-check-out/',
};
export default endPoints;
